@import '~@coreui/coreui-pro/scss/coreui.scss';

:root {
  --amplify-primary-color: #4799eb;
  --amplify-primary-tint: #ff7359;
  --amplify-primary-shade: #e0573e;
}

.form-control.alert-danger {
  color: #772b35;
  background-color: #fadddd;
  border-color: #f8cfcf;
}

// https://docs.amplify.aws/ui/customization/theming/q/framework/react#for-colors
